import { type MouseEventHandler, type PropsWithChildren, type ReactElement, useRef } from "react";
import clsx from "clsx";

import TransitionLayout from "../TransitionLayout";
import Spinner from "../Spinner";

import styles from "./styles.module.scss";

export interface ButtonProps extends PropsWithChildren {
  disabled?: boolean;
  inProcess?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export default function Button({
                                 children,
                                 disabled,
                                 inProcess,
                                 onClick,
                                 className,
                               }: ButtonProps): ReactElement {
  const spinnerRef = useRef(null);

  return (
    <button
      className={clsx(styles.Button, className)}
      type="button"
      onClick={(event) => {
        if (disabled ?? inProcess) return;

        onClick(event);
      }}
      disabled={disabled}
    >
      {
        !inProcess && (
          <span className={styles.Button__text}>{children}</span>
        )
      }
      <TransitionLayout id="button-spinner" shown={!!inProcess} ref={spinnerRef} className={styles.Button__spinner}>
        <Spinner ref={spinnerRef} />
      </TransitionLayout>
    </button>
  );
}
