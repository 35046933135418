import { type JSX } from "react";

export default function ArrowIcon(): JSX.Element {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 12V1M5.5 1L10 6.19444M5.5 1L1 6.19444" stroke="#7BA0CA" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  );
}
