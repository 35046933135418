import { createContext, type PropsWithChildren, type ReactElement, useEffect, useState } from "react";

const viewportWidth = 320;
const viewportHeight = 568;
const standLength = 40;

export interface ScreenData {
  screenWidth: number;
  screenHeight: number;
  viewportWidth: number;
  viewportHeight: number;
  standLength: number;
}

const getStaticSize = (): {
  screenRealWidth: number;
  screenRealHeight: number;
  screenWidth: number;
  screenHeight: number;
} => {
  const screenRealWidth = window.innerWidth;
  const screenRealHeight = window.innerHeight;

  return {
    screenRealWidth,
    screenRealHeight,
    screenWidth: screenRealWidth < screenRealHeight ? screenRealWidth : screenRealHeight,
    screenHeight: screenRealHeight > screenRealWidth ? screenRealHeight : screenRealWidth,
  };
};

export const ScreenContext = createContext<ScreenData>({
  screenWidth: getStaticSize().screenWidth,
  screenHeight: getStaticSize().screenHeight,
  viewportWidth,
  viewportHeight,
  standLength,
});

export const ScreenProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [windowSize] = useState<ScreenData>({
    screenWidth: getStaticSize().screenWidth,
    screenHeight: getStaticSize().screenHeight,
    viewportWidth,
    viewportHeight,
    standLength,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function handleResize() {
      const root = document.querySelector("#root");

      const { screenWidth, screenHeight, screenRealWidth, screenRealHeight } = getStaticSize();

      if (root) {
        root.setAttribute("style", `width: ${screenWidth}px; height: ${screenHeight}px; ${screenRealWidth > screenRealHeight ? "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) rotate(-90deg);" : ""}`);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ScreenContext.Provider value={windowSize}>{children}</ScreenContext.Provider>
  );
};
