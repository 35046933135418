import { getQueryParam } from "../utils/route";

const apiConstants = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Auth-Key": getQueryParam("auth") ?? "",
    "X-Platform": window?.Telegram?.WebApp?.platform || "unknown",
  },
};

export default apiConstants;
