import { type Dispatch, type ReactElement, type SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import {
  Body,
  Bounds,
  Composite,
  type Constraint,
  Engine,
  Events,
  type ICollisionCallback,
  Render,
  Runner,
  Vector,
  World,
} from "matter-js";

import { type PostGameData } from "../../index";
import { useScreen } from "../../../../hooks/screen/useScreen";
import { usePopup } from "../../../../../components/Popup/hook/usePopup";
import useGameArea from "../../hooks/useGameArea";
import { useGround } from "../../objects/Ground";
import { useIceRink } from "../../objects/IceRink";
import { useWall } from "../../objects/Wall";
import { useSnowflake } from "../../objects/Snowflake";
import { useFigureSkater } from "../../objects/FigureSkater";
import { useTeddy } from "../../objects/Teddy";
import { usePlayer } from "../../objects/Player";
import { getAngle } from "../../helpers/figure";
import { useSpotlight } from "../../objects/Spotlight";
import { isBodyStopped, reduceFlyoffSpeed } from "../../helpers/physics";
import { calculateScore } from "../../helpers/score";
import { getCameraDeltaPosition } from "../../helpers/camera";
import AboveScreenIndication from "./components/AboveScreenIndication";
import Tribune from "../../components/Tribune";

import styles from "./styles.module.scss";

interface GameScreenProps {
  setShownScore: Dispatch<SetStateAction<boolean>>;
  setScoreValue: (value: number) => void;
  startGameAttempts: () => Promise<void>;
  postGameData: (requestData: PostGameData) => Promise<void>;
  endAttempt: () => void;
}

const GameScreen = ({
                      setShownScore,
                      setScoreValue,
                      endAttempt,
                      startGameAttempts,
                      postGameData,
                    }: GameScreenProps): ReactElement => {
  const { screenWidth, screenHeight, viewportWidth, standLength } = useScreen();
  const { hidePopup } = usePopup();
  const { startRespawnSnowflake, stopRespawnSnowflake } = useGameArea();

  const tribuneRef = useRef<HTMLDivElement>(null);
  const aboveScreenIndicationComponentRef = useRef<HTMLDivElement>(null);

  const { getGround } = useGround();
  const { iceRinkData, getIceRink } = useIceRink();
  const { getWalls } = useWall();
  const { getSnowflakes, respawnSnowflake } = useSnowflake();

  const {
    getFigureSkaterBody,
    getFigureSkaterSkirt,
    getFigureSkaterLegLeft,
    getFigureSkaterLegRight,
    getFigureSkaterHandLeft,
    getFigureSkaterHandRight,
    getFigureSkaterShoulderLeft,
    getFigureSkaterShoulderRight,
    getFigureSkaterGrip,
    getFigureSkater,
    getFigureSkaterCopy,
  } = useFigureSkater();

  const {
    getSpotlight,
  } = useSpotlight();

  const {
    teddyData,
    getTeddy,
    getTeddyTexture,
    getTeddyConstraintLeft,
    getTeddyConstraintMiddle,
    getTeddyConstraintRight,
  } = useTeddy();

  const {
    getPlayerHand,
    getPlayerBody,
    getPlayerConstraint,
    getPlayer,
  } = usePlayer();

  const attemptIsStarting = useRef<boolean>(false);
  const currentScoreValue = useRef<number>(0);

  const sceneRef = useRef<HTMLCanvasElement>(null);
  const renderRef = useRef<Render | null>(null);
  const engineRef = useRef<Engine>(Engine.create({
    gravity: {
      x: 0,
      y: 0.275,
    },
    // constraintIterations: 5,
    // positionIterations: 5,
    // velocityIterations: 5,
  }));

  const figureSkaterRef = useRef<Composite | null>(null);
  const figureSkaterBodyRef = useRef<Body | null>(null);
  const figureSkaterSkirtRef = useRef<Body | null>(null);
  const figureSkaterHandLeftRef = useRef<Body | null>(null);
  const figureSkaterHandRightRef = useRef<Body | null>(null);
  const figureSkaterLegLeftRef = useRef<Body | null>(null);
  const figureSkaterLegRightRef = useRef<Body | null>(null);
  const figureSkaterShoulderLeftRef = useRef<Body | null>(null);
  const figureSkaterShoulderRightRef = useRef<Body | null>(null);
  const figureSkaterGripRef = useRef<Body | null>(null);
  const spotlightRef = useRef<Body | null>(null);

  const playerHandRef = useRef<Body | null>(null);
  const teddyRef = useRef<Body | null>(null);
  const teddyTextureRef = useRef<Body | null>(null);

  const teddyConstraintLeftRef = useRef<Constraint | null>(null);
  const teddyConstraintMiddleRef = useRef<Constraint | null>(null);
  const teddyConstraintRightRef = useRef<Constraint | null>(null);

  const snowflakesRef = useRef<Composite | null>(null);

  const initialCameraIsActive = false;
  const cameraIsActive = useRef<boolean>(initialCameraIsActive);

  const throwForceReductionFactor = 0.065;

  const [shownTeddyBodyAboveScreenValue, setShownTeddyBodyAboveScreenValue] = useState<boolean>(false);

  const rotateHandAngularVelocity = useRef<number>(0.023);
  const rotateHandMaxAngularVelocity = 0.11;
  const rotateHandAngularAcceleration = 0.000012;

  const rotatePlayerHand = useCallback(() => {
    if (!playerHandRef.current) return;

    const newAngularVelocity = rotateHandAngularVelocity.current + rotateHandAngularAcceleration;

    rotateHandAngularVelocity.current = Math.min(
      newAngularVelocity,
      rotateHandMaxAngularVelocity,
    );

    Body.setAngularVelocity(playerHandRef.current, rotateHandAngularVelocity.current);
  }, []);

  const startRotatePlayerHand = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "afterUpdate", rotatePlayerHand);
  }, [rotatePlayerHand]);

  const stopRotatePlayerHand = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", rotatePlayerHand);

    if (playerHandRef.current) {
      playerHandRef.current.isStatic = true;
    }
  }, [rotatePlayerHand]);

  const figureSkaterEaseDistance = 70;
  const figureSkaterCurrentPositionX = useRef<number>(0);
  const figureSkaterInitialPositionX = useRef<number>(0);
  const figureSkaterCurrentVelocity = useRef<number>(0.39);
  const figureSkaterIsBraking = useRef<boolean>(false);
  const isChangingFigureSkaterPosition = useRef<boolean>(false);

  const followCameraFromTeddyToFigureSkaterCenterX = useRef(0);

  const updateTeddyTexture = useCallback(() => {
    if (teddyRef.current && teddyTextureRef.current) {
      Body.setPosition(teddyTextureRef.current, {
        x: teddyRef.current.position.x,
        y: teddyRef.current.position.y,
      });
      Body.setVelocity(teddyTextureRef.current, teddyRef.current.velocity);
      Body.setAngle(teddyTextureRef.current, teddyRef.current.angle);
      Body.setAngularVelocity(teddyTextureRef.current, teddyRef.current.angularVelocity);
    }
  }, []);

  const followTeddyWithFigureSkaterHand = useCallback((offset: Vector) => {
    if (teddyRef.current && figureSkaterGripRef.current) {
      Body.setPosition(teddyRef.current, {
        x: figureSkaterGripRef.current.position.x - offset.x,
        y: figureSkaterGripRef.current.position.y + offset.y,
      });
      Body.setVelocity(teddyRef.current, figureSkaterGripRef.current.velocity);
      Body.setAngularVelocity(teddyRef.current, figureSkaterGripRef.current.angularVelocity);
    }
  }, []);

  const getFigureSkaterBodyWithHandsComposite = useCallback((): Composite | undefined => {
    if (!figureSkaterRef.current) {
      return;
    }

    return figureSkaterRef.current.composites.find(({ label }) => label === "figureSkaterBodyWithHands");
  }, []);

  const getFigureSkaterSkirtBody = useCallback((): Body | undefined => {
    const figureSkaterBodyWithHands = getFigureSkaterBodyWithHandsComposite();

    if (!figureSkaterBodyWithHands) {
      return;
    }

    return figureSkaterBodyWithHands.bodies.find(({ label }) => label === "figureSkaterSkirt");
  }, [getFigureSkaterBodyWithHandsComposite]);

  const getFigureSkaterHandLeftBody = useCallback((): Body | undefined => {
    const figureSkaterBodyWithHands = getFigureSkaterBodyWithHandsComposite();

    if (!figureSkaterBodyWithHands) {
      return;
    }

    return figureSkaterBodyWithHands.bodies.find(({ label }) => label === "figureSkaterHandLeft");
  }, [getFigureSkaterBodyWithHandsComposite]);

  const getFigureSkaterHandRightBody = useCallback((): Body | undefined => {
    const figureSkaterBodyWithHands = getFigureSkaterBodyWithHandsComposite();

    if (!figureSkaterBodyWithHands) {
      return;
    }

    return figureSkaterBodyWithHands.bodies.find(({ label }) => label === "figureSkaterHandRight");
  }, [getFigureSkaterBodyWithHandsComposite]);

  const getFigureSkaterLegLeftBody = useCallback((): Body | undefined => {
    if (!figureSkaterRef.current) {
      return;
    }

    return figureSkaterRef.current.bodies.find(({ label }) => label === "figureSkaterLegLeft");
  }, []);

  const getFigureSkaterLegRightBody = useCallback((): Body | undefined => {
    if (!figureSkaterRef.current) {
      return;
    }

    return figureSkaterRef.current.bodies.find(({ label }) => label === "figureSkaterLegRight");
  }, []);

  const moveCameraPosition = useCallback((currentBounds: Bounds, newCameraDeltaPositionX: number, newCameraCenterX: number, offset: number) => {
    if (!engineRef.current) {
      return;
    }

    if (tribuneRef.current) {
      const tribunePosition = screenWidth / 2 - offset - newCameraCenterX;

      tribuneRef.current.style.transform = `translateX(${tribunePosition}px)`;
    }

    Bounds.translate(currentBounds, { x: newCameraDeltaPositionX, y: 0 });
  }, [screenWidth]);

  const figureSkaterRotationBodyDataRef = useRef({
    angle: 0,
    target: getAngle(-49),
    fantomTarget: getAngle(-70),
    speed: getAngle(0.2),
    isRotating: true,
    direction: -1,
  });

  const figureSkaterGripPosition = useRef<Vector>({ x: 0, y: 0 });

  const inclineFigureSkater = useCallback(() => {
    const figureSkaterBody = getFigureSkaterBodyWithHandsComposite();
    const rotation = figureSkaterRotationBodyDataRef.current;

    if (!figureSkaterBody || !figureSkaterSkirtRef.current) {
      return;
    }

    if (figureSkaterBody) {
      const changeInAngle = rotation.speed * rotation.direction;

      rotation.angle += changeInAngle;

      if (changeInAngle > 0 && rotation.angle >= 0) {
        rotation.angle = rotation.target;
        rotation.isRotating = false;

        Events.off(engineRef.current, "afterUpdate", inclineFigureSkater);

        endAttempt();
      } else if (changeInAngle < 0 && rotation.angle <= rotation.target && rotation.angle > rotation.fantomTarget) {
        rotation.isRotating = false;
      } else if (changeInAngle < 0 && rotation.angle <= rotation.fantomTarget) {
        rotation.direction = 1;
      } else if (!rotation.isRotating && changeInAngle > 0 && rotation.angle >= rotation.target) {
        rotation.isRotating = true;

        if (figureSkaterGripRef.current && teddyTextureRef.current) {
          figureSkaterGripPosition.current = {
            x: figureSkaterGripRef.current.position.x - teddyTextureRef.current.position.x,
            y: teddyTextureRef.current.position.y - figureSkaterGripRef.current.position.y,
          };
        }
      }

      if (rotation.isRotating) {
        if (changeInAngle > 0) {
          followTeddyWithFigureSkaterHand(figureSkaterGripPosition.current);
        }

        Composite.rotate(figureSkaterBody, changeInAngle, {
          x: figureSkaterSkirtRef.current.position.x - 10,
          y: figureSkaterSkirtRef.current.position.y - 10,
        });
      }
    }
  }, [endAttempt, followTeddyWithFigureSkaterHand, getFigureSkaterBodyWithHandsComposite]);

  const toggleShownFigureSkater = useCallback((visible: boolean) => {
    if (!figureSkaterRef.current) {
      return;
    }

    Composite.allComposites(figureSkaterRef.current).forEach((composite) => {
      Composite.allBodies(composite).forEach((body) => {
        body.render.visible = visible;
      });
    });

    Composite.allBodies(figureSkaterRef.current).forEach((body) => {
      body.render.visible = visible;
    });
  }, []);

  const toggleShownSpotlight = useCallback((visible: boolean) => {
    if (!spotlightRef.current) {
      return;
    }

    spotlightRef.current.render.visible = visible;
  }, []);

  const changeFigureSkaterSkirtIndex = useCallback(() => {
    if (
      !figureSkaterRef.current
      || !figureSkaterBodyRef.current
      || !figureSkaterSkirtRef.current
      || !figureSkaterLegLeftRef.current
      || !figureSkaterLegRightRef.current
      || !figureSkaterHandLeftRef.current
      || !figureSkaterHandRightRef.current
      || !figureSkaterShoulderLeftRef.current
      || !figureSkaterShoulderRightRef.current
      || !figureSkaterGripRef.current
    ) {
      return;
    }

    World.remove(engineRef.current.world, figureSkaterRef.current);

    figureSkaterRef.current = getFigureSkaterCopy(
      figureSkaterBodyRef.current,
      figureSkaterSkirtRef.current,
      figureSkaterLegLeftRef.current,
      figureSkaterLegRightRef.current,
      figureSkaterHandLeftRef.current,
      figureSkaterHandRightRef.current,
      figureSkaterShoulderLeftRef.current,
      figureSkaterShoulderRightRef.current,
      figureSkaterGripRef.current,
    );

    World.addComposite(engineRef.current.world, figureSkaterRef.current);
  }, [getFigureSkaterCopy]);

  const startInclineFigureSkater = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "afterUpdate", inclineFigureSkater);
  }, [inclineFigureSkater]);

  const stopInclineFigureSkater = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", inclineFigureSkater);
  }, [inclineFigureSkater]);

  const geFigureSkaterRotate = useCallback((endAngle: number, startAngle: number, startX: number, currentX: number) => {
    const swingDistance = 220;
    const pauseDistance = 100;
    const fullCycleDistance = 2 * swingDistance + 2 * pauseDistance;

    const distanceFromStartX = Math.abs(currentX - startX) % fullCycleDistance;
    let angleProgress: number;

    if (distanceFromStartX < swingDistance) {
      angleProgress = distanceFromStartX / swingDistance;
      return startAngle + angleProgress * (endAngle - startAngle);
    } else if (distanceFromStartX < swingDistance + pauseDistance) {
      return endAngle;
    } else if (distanceFromStartX < 2 * swingDistance + pauseDistance) {
      angleProgress = (distanceFromStartX - swingDistance - pauseDistance) / swingDistance;
      return endAngle - angleProgress * (endAngle - startAngle);
    } else {
      return startAngle;
    }
  }, []);

  const rotateFigureSkaterLeftHand = useCallback((position: number, startPosition: number): void => {
    const figureSkaterHandLeft = getFigureSkaterHandLeftBody();

    if (!figureSkaterHandLeft) {
      return;
    }

    const maxAngle = -97;

    const angle = geFigureSkaterRotate(0, maxAngle, startPosition, position);

    if (typeof angle !== "undefined") {
      Body.setAngle(figureSkaterHandLeft, getAngle(angle));
    }
  }, [geFigureSkaterRotate, getFigureSkaterHandLeftBody]);

  const rotateFigureSkaterRightHand = useCallback((position: number, startPosition: number): void => {
    const figureSkaterHandRight = getFigureSkaterHandRightBody();

    if (!figureSkaterHandRight) {
      return;
    }

    const maxAngle = 60.33;

    const angle = geFigureSkaterRotate(0, maxAngle, startPosition, position);

    if (typeof angle !== "undefined") {
      Body.setAngle(figureSkaterHandRight, getAngle(angle));
    }
  }, [geFigureSkaterRotate, getFigureSkaterHandRightBody]);

  const rotateFigureSkaterLeftLeg = useCallback((position: number, startPosition: number): void => {
    const figureSkaterLegLeft = getFigureSkaterLegLeftBody();

    if (!figureSkaterLegLeft) {
      return;
    }

    const maxAngle = -20;

    const angle = geFigureSkaterRotate(0, maxAngle, startPosition, position);

    if (typeof angle !== "undefined") {
      Body.setAngle(figureSkaterLegLeft, getAngle(angle));
    }
  }, [geFigureSkaterRotate, getFigureSkaterLegLeftBody]);

  const rotateFigureSkaterRightLeg = useCallback((position: number, startPosition: number): void => {
    const figureSkaterLegRight = getFigureSkaterLegRightBody();

    if (!figureSkaterLegRight) {
      return;
    }

    const maxAngle = 45;

    const angle = geFigureSkaterRotate(0, maxAngle, startPosition, position);

    if (typeof angle !== "undefined") {
      Body.setAngle(figureSkaterLegRight, getAngle(angle));
    }
  }, [geFigureSkaterRotate, getFigureSkaterLegRightBody]);

  const slipFigureSkater = useCallback(() => {
    const figureSkaterSkirt = getFigureSkaterSkirtBody();

    if (!figureSkaterSkirt || !teddyRef.current) {
      return;
    }

    const position: number = figureSkaterSkirt.position.x;
    const startPosition = figureSkaterInitialPositionX.current;

    rotateFigureSkaterLeftHand(position, startPosition);
    rotateFigureSkaterRightHand(position, startPosition);
    rotateFigureSkaterLeftLeg(position, startPosition);
    rotateFigureSkaterRightLeg(position, startPosition);
  }, [getFigureSkaterSkirtBody, rotateFigureSkaterLeftHand, rotateFigureSkaterLeftLeg, rotateFigureSkaterRightHand, rotateFigureSkaterRightLeg]);

  const updateCameraForFigureSkaterPosition = useCallback(() => {
    const figureSkaterSkirt = getFigureSkaterSkirtBody();

    if (!figureSkaterSkirt || !renderRef.current) {
      return;
    }

    const currentBounds = renderRef.current.bounds;

    const { position: { x: figureSkaterPositionX } } = figureSkaterSkirt;

    const offset = teddyData.width / 2 - 56;

    const {
      x: newCameraDeltaPositionX,
      newCameraCenterX,
    } = getCameraDeltaPosition({
      followBodyCenterX: figureSkaterPositionX,
      currentBounds,
      offset,
      lerpFactor: 1,
    });

    moveCameraPosition(currentBounds, newCameraDeltaPositionX, newCameraCenterX, offset);
  }, [getFigureSkaterSkirtBody, moveCameraPosition, teddyData.width]);

  const startUpdateCameraForFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "afterUpdate", updateCameraForFigureSkaterPosition);
  }, [updateCameraForFigureSkaterPosition]);

  const stopUpdateCameraForFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", updateCameraForFigureSkaterPosition);
  }, [updateCameraForFigureSkaterPosition]);

  const translateFigureSkater = useCallback((x: number) => {
    if (!figureSkaterRef.current) {
      return;
    }

    Composite.translate(figureSkaterRef.current, { x, y: 0 });

    figureSkaterRef.current.composites.forEach((composite) => {
      Composite.translate(composite, { x, y: 0 });
    });
  }, []);

  const translateSpotlight = useCallback((x: number) => {
    if (!spotlightRef.current) {
      return;
    }

    Body.translate(spotlightRef.current, { x, y: 0 });
  }, []);

  const changeFigureSkaterPosition = useCallback(() => {
    if (!figureSkaterRef.current || !engineRef.current || !teddyRef.current || !isChangingFigureSkaterPosition.current) return;

    const targetX = teddyRef.current.position.x + 18;
    const distanceToTarget = figureSkaterCurrentPositionX.current - targetX;

    if (figureSkaterCurrentPositionX.current <= (targetX + figureSkaterEaseDistance)) {
      figureSkaterIsBraking.current = true;
    }

    let newVelocity = figureSkaterCurrentVelocity.current;

    if (!figureSkaterIsBraking.current) {
      // newVelocity -= 0.0001;
    } else {
      const t = distanceToTarget / figureSkaterEaseDistance;
      const easeFactor = (1 - Math.pow(1 - t, 5));
      newVelocity *= easeFactor;
    }

    const moveX = Math.max(Math.min(newVelocity, distanceToTarget), 0);

    translateFigureSkater(-moveX);
    translateSpotlight(-moveX);

    figureSkaterCurrentVelocity.current = newVelocity;
    figureSkaterCurrentPositionX.current -= moveX;

    if (newVelocity <= 0.001) {
      changeFigureSkaterSkirtIndex();
      startInclineFigureSkater();

      figureSkaterCurrentVelocity.current = 0;
      figureSkaterCurrentPositionX.current = targetX;
      isChangingFigureSkaterPosition.current = false;

      Events.off(engineRef.current, "afterUpdate", changeFigureSkaterPosition);

      stopUpdateCameraForFigureSkaterPosition();
    } else {
      slipFigureSkater();
    }
  }, [changeFigureSkaterSkirtIndex, slipFigureSkater, startInclineFigureSkater, stopUpdateCameraForFigureSkaterPosition, translateFigureSkater, translateSpotlight]);

  const startChangeFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current || isChangingFigureSkaterPosition.current) return;

    Events.on(engineRef.current, "afterUpdate", changeFigureSkaterPosition);
  }, [changeFigureSkaterPosition]);

  const stopChangeFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", changeFigureSkaterPosition);
  }, [changeFigureSkaterPosition]);

  const updateCameraFromTeddyToFigureSkaterPosition = useCallback(() => {
    if (!renderRef.current || !teddyRef.current) {
      return;
    }

    const currentBounds = renderRef.current.bounds;
    const offset = teddyData.width / 2 - 56;
    const newFollowBodyCenterX = followCameraFromTeddyToFigureSkaterCenterX.current + 0.95;

    if (!isChangingFigureSkaterPosition.current) {
      startChangeFigureSkaterPosition();
      isChangingFigureSkaterPosition.current = true;
    }

    if (newFollowBodyCenterX >= figureSkaterCurrentPositionX.current) {
      startUpdateCameraForFigureSkaterPosition();

      Events.off(engineRef.current, "afterUpdate", updateCameraFromTeddyToFigureSkaterPosition);

      return;
    }

    const {
      x: newCameraDeltaPositionX,
      newCameraCenterX,
    } = getCameraDeltaPosition({
      followBodyCenterX: newFollowBodyCenterX,
      currentBounds,
      offset,
      lerpFactor: 1,
    });

    moveCameraPosition(currentBounds, newCameraDeltaPositionX, newCameraCenterX, offset);

    followCameraFromTeddyToFigureSkaterCenterX.current = newFollowBodyCenterX;
  }, [moveCameraPosition, startChangeFigureSkaterPosition, startUpdateCameraForFigureSkaterPosition, teddyData.width]);

  const startUpdateCameraFromTeddyToFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current || !teddyRef.current) return;

    followCameraFromTeddyToFigureSkaterCenterX.current = teddyRef.current.position.x;

    Events.on(engineRef.current, "afterUpdate", updateCameraFromTeddyToFigureSkaterPosition);
  }, [updateCameraFromTeddyToFigureSkaterPosition]);

  const stopUpdateCameraFromTeddyToFigureSkaterPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", updateCameraFromTeddyToFigureSkaterPosition);
  }, [updateCameraFromTeddyToFigureSkaterPosition]);

  const updateCameraForTeddyPosition = useCallback(() => {
    if (!renderRef.current || !teddyRef.current || !cameraIsActive.current) return;

    const { position: { x: teddyBodyPositionX, y: teddyBodyPositionY }, velocity } = teddyRef.current;

    const startXPositionIceRink = iceRinkData.x - iceRinkData.width / 2;

    const newScoreValue = calculateScore({
      position: teddyBodyPositionX,
      offset: startXPositionIceRink,
      isActive: teddyBodyPositionY < iceRinkData.y,
    });

    if (teddyBodyPositionX > startXPositionIceRink && teddyBodyPositionY < iceRinkData.y) {
      currentScoreValue.current = newScoreValue;

      setScoreValue(newScoreValue);
    }

    const currentBounds = renderRef.current.bounds;

    if (teddyBodyPositionX > 0 && velocity.x > 0) {
      const offset = teddyData.width / 2 - 56;

      const {
        x: newCameraDeltaPositionX,
        newCameraCenterX,
      } = getCameraDeltaPosition({
        followBodyCenterX: teddyBodyPositionX,
        currentBounds,
        offset,
        lerpFactor: 1,
      });

      if (newCameraDeltaPositionX > 0 && teddyBodyPositionX > 0) {
        moveCameraPosition(currentBounds, newCameraDeltaPositionX, newCameraCenterX, offset);
      }
    }

    if (teddyRef.current) {
      if (isBodyStopped(teddyRef.current)) {
        Body.setStatic(teddyRef.current, true);

        cameraIsActive.current = false;
        Events.off(engineRef.current, "afterUpdate", updateCameraForTeddyPosition);

        if (newScoreValue > 0) {
          setShownScore(false);

          if (figureSkaterRef.current) {
            const figureSkaterInitialPosition = teddyBodyPositionX + 320 * 3;

            translateFigureSkater(figureSkaterInitialPosition);
            translateSpotlight(figureSkaterInitialPosition);

            figureSkaterInitialPositionX.current = figureSkaterInitialPosition;
            figureSkaterCurrentPositionX.current = figureSkaterInitialPosition;

            startUpdateCameraFromTeddyToFigureSkaterPosition();

            toggleShownFigureSkater(true);
            toggleShownSpotlight(true);
          }
        } else {
          endAttempt();
        }

        void postGameData({ value: newScoreValue })
          .then();
      }
    }

    const aboveScreenValue = Math.trunc(teddyBodyPositionY);

    if (aboveScreenIndicationComponentRef.current) {
      aboveScreenIndicationComponentRef.current.innerHTML = `${aboveScreenValue}`;
    }

    setShownTeddyBodyAboveScreenValue((prevValue) => {
      const newValue = teddyBodyPositionY < 0;

      if (newValue === prevValue) {
        return prevValue;
      }

      return newValue;
    });
  }, [endAttempt, iceRinkData.width, iceRinkData.x, iceRinkData.y, moveCameraPosition, postGameData, setScoreValue, setShownScore, startUpdateCameraFromTeddyToFigureSkaterPosition, teddyData.width, toggleShownFigureSkater, toggleShownSpotlight, translateFigureSkater, translateSpotlight]);

  const startUpdateCameraForTeddyPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "afterUpdate", updateCameraForTeddyPosition);
  }, [updateCameraForTeddyPosition]);

  const stopUpdateCameraForTeddyPosition = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", updateCameraForTeddyPosition);
  }, [updateCameraForTeddyPosition]);

  const isTeddyStopping = useRef<boolean>(false);
  const teddySlowingForceMagnitude = 0.0005;

  const stoppingTeddy = useCallback(() => {
    if (!teddyRef.current || !isTeddyStopping.current) {
      return;
    }

    if (teddyRef.current.speed <= 0.01) {
      Body.setVelocity(teddyRef.current, { x: 0, y: 0 });
      Body.setAngularVelocity(teddyRef.current, 0);
    } else {
      const force = {
        x: -teddyRef.current.velocity.x * teddySlowingForceMagnitude,
        y: 0,
      };

      Body.applyForce(teddyRef.current, teddyRef.current.position, force);
    }
  }, []);

  const startStoppingTeddy = useCallback(() => {
    if (!engineRef.current || isTeddyStopping.current) return;

    isTeddyStopping.current = true;

    Events.on(engineRef.current, "afterUpdate", stoppingTeddy);
  }, [stoppingTeddy]);

  const stopStoppingTeddy = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", stoppingTeddy);
  }, [stoppingTeddy]);

  const checkCollision = useCallback<ICollisionCallback>((event) => {
    const pairs = event.pairs;

    for (let i = 0, j = pairs.length; i !== j; ++i) {
      const pair = pairs[i];

      if (pair.bodyA.label === "Teddy" || pair.bodyB.label === "Teddy") {
        if (teddyRef.current) {
          const { position: { x: teddyBodyX, y: teddyBodyY } } = teddyRef.current;

          Body.setAngularVelocity(teddyRef.current, 0);

          if ((teddyBodyX <= (iceRinkData.x - iceRinkData.width / 2)) || teddyBodyY > iceRinkData.y) {
            Body.setStatic(teddyRef.current, true);
            isTeddyStopping.current = false;
          } else {
            startStoppingTeddy();
          }
        }
      }
    }
  }, [iceRinkData.width, iceRinkData.x, iceRinkData.y, startStoppingTeddy]);

  const startCheckCollision = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "collisionStart", checkCollision);
  }, [checkCollision]);

  const stopCheckCollision = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "collisionStart", checkCollision);
  }, [checkCollision]);

  const detachTeddyFromHand = useCallback(() => {
    if (attemptIsStarting.current) {
      return;
    }

    startUpdateCameraForTeddyPosition();
    startCheckCollision();

    attemptIsStarting.current = true;

    hidePopup();

    void startGameAttempts()
      .then();

    if (
      playerHandRef.current &&
      teddyRef.current &&
      teddyConstraintLeftRef.current &&
      teddyConstraintMiddleRef.current &&
      teddyConstraintRightRef.current &&
      engineRef.current?.world
    ) {
      const playerHand = playerHandRef.current;
      const teddyBody = teddyRef.current;
      const teddyConstraintLeft = teddyConstraintLeftRef.current;
      const teddyConstraintMiddle = teddyConstraintMiddleRef.current;
      const teddyConstraintRight = teddyConstraintRightRef.current;

      if (teddyBody) {
        const radius = Vector.sub(teddyBody.position, playerHand.position);
        const tangentialVelocity = Vector.perp(radius);
        const magnitude = playerHand.angularVelocity * Vector.magnitude(radius);
        const adjustedVelocity = Vector.mult(tangentialVelocity, magnitude);

        const reducedVelocity = reduceFlyoffSpeed(adjustedVelocity, throwForceReductionFactor);

        Body.setVelocity(teddyBody, reducedVelocity);
      }

      stopRotatePlayerHand();

      World.remove(engineRef.current.world, teddyConstraintLeft);
      World.remove(engineRef.current.world, teddyConstraintMiddle);
      World.remove(engineRef.current.world, teddyConstraintRight);

      teddyConstraintLeftRef.current = null;
      teddyConstraintMiddleRef.current = null;
      teddyConstraintRightRef.current = null;

      cameraIsActive.current = true;
    }
  }, [hidePopup, startCheckCollision, startGameAttempts, startUpdateCameraForTeddyPosition, stopRotatePlayerHand]);

  const watchRespawnSnowflake = useCallback(() => {
    if (!engineRef.current) return;

    respawnSnowflake(engineRef.current.world, viewportWidth * standLength);
  }, [respawnSnowflake, standLength, viewportWidth]);

  const startUpdateTeddyTexture = useCallback(() => {
    if (!engineRef.current) return;

    Events.on(engineRef.current, "afterUpdate", updateTeddyTexture);
  }, [updateTeddyTexture]);

  const stopUpdateTeddyTexture = useCallback(() => {
    if (!engineRef.current) return;

    Events.off(engineRef.current, "afterUpdate", updateTeddyTexture);
  }, [updateTeddyTexture]);

  useEffect(() => {
    const engine = engineRef.current;
    const canvas = sceneRef.current;

    if (!canvas) {
      return;
    }

    renderRef.current = Render.create({
      canvas,
      engine,
      options: {
        hasBounds: true,
        background: "transparent",
        width: screenWidth,
        height: screenHeight,
        pixelRatio: window.devicePixelRatio,
        wireframes: false,
        // showPerformance: true
      },
    });

    const runner = Runner.create({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      maxFrameTime: 1000 / 50,
      delta: 1000 / (60 * 10),
    });

    const figureSkaterBody = getFigureSkaterBody();
    const figureSkaterSkirt = getFigureSkaterSkirt();
    const figureSkaterLegLeft = getFigureSkaterLegLeft();
    const figureSkaterLegRight = getFigureSkaterLegRight();
    const figureSkaterHandLeft = getFigureSkaterHandLeft();
    const figureSkaterHandRight = getFigureSkaterHandRight();
    const figureSkaterShoulderLeft = getFigureSkaterShoulderLeft();
    const figureSkaterShoulderRight = getFigureSkaterShoulderRight();
    const figureSkaterGrip = getFigureSkaterGrip();

    const figureSkater = getFigureSkater(figureSkaterBody, figureSkaterSkirt, figureSkaterLegLeft, figureSkaterLegRight, figureSkaterHandLeft, figureSkaterHandRight, figureSkaterShoulderLeft, figureSkaterShoulderRight, figureSkaterGrip);

    const spotlight = getSpotlight({
      x: 10,
      y: screenHeight - 160,
      width: 92,
      height: iceRinkData.y - iceRinkData.height / 2 + 20 + 60,
    });

    const playerHand = getPlayerHand();
    const playerBody = getPlayerBody();
    const teddy = getTeddy();
    const teddyTexture = getTeddyTexture();

    const playerConstraint = getPlayerConstraint(playerHand);
    const player = getPlayer(playerBody);

    const teddyConstraintLeft = getTeddyConstraintLeft(teddy, playerHand);
    const teddyConstraintMiddle = getTeddyConstraintMiddle(teddy, playerHand);
    const teddyConstraintRight = getTeddyConstraintRight(teddy, playerHand);

    const snowflakes = getSnowflakes(viewportWidth * standLength, 25);

    const iceRink = getIceRink();
    const ground = getGround();
    const walls = getWalls();


    World.add(engineRef.current.world, [
      spotlight,
      player,
      teddy,
      teddyTexture,
      teddyConstraintLeft,
      teddyConstraintMiddle,
      teddyConstraintRight,
      playerHand,
      playerConstraint,
      figureSkater,
      iceRink,
      ground,
      walls,
      snowflakes,
    ]);

    spotlightRef.current = spotlight;

    figureSkaterRef.current = figureSkater;
    figureSkaterBodyRef.current = figureSkaterBody;
    figureSkaterSkirtRef.current = figureSkaterSkirt;
    figureSkaterHandLeftRef.current = figureSkaterHandLeft;
    figureSkaterHandRightRef.current = figureSkaterHandRight;
    figureSkaterLegLeftRef.current = figureSkaterLegLeft;
    figureSkaterLegRightRef.current = figureSkaterLegRight;
    figureSkaterShoulderLeftRef.current = figureSkaterShoulderLeft;
    figureSkaterShoulderRightRef.current = figureSkaterShoulderRight;
    figureSkaterGripRef.current = figureSkaterGrip;

    playerHandRef.current = playerHand;

    teddyRef.current = teddy;
    teddyTextureRef.current = teddyTexture;
    teddyConstraintLeftRef.current = teddyConstraintLeft;
    teddyConstraintMiddleRef.current = teddyConstraintMiddle;
    teddyConstraintRightRef.current = teddyConstraintRight;

    snowflakesRef.current = snowflakes;

    toggleShownFigureSkater(false);
    toggleShownSpotlight(false);

    startRespawnSnowflake(engineRef.current, watchRespawnSnowflake);
    startUpdateTeddyTexture();

    Runner.run(runner, engineRef.current);
    Render.run(renderRef.current);

    const engineTmp = engineRef.current;
    const renderTmp = renderRef.current;

    return () => {
      stopRotatePlayerHand();
      stopUpdateCameraForTeddyPosition();
      stopUpdateCameraForFigureSkaterPosition();
      stopInclineFigureSkater();
      stopUpdateCameraFromTeddyToFigureSkaterPosition();
      stopCheckCollision();
      stopRespawnSnowflake(engine, watchRespawnSnowflake);
      stopChangeFigureSkaterPosition();
      stopUpdateTeddyTexture();
      stopStoppingTeddy();

      Render.stop(renderTmp);
      renderTmp.canvas.remove();

      Runner.stop(runner);
      World.clear(engineTmp.world, false);
      Engine.clear(engineTmp);

      attemptIsStarting.current = false;
    };
  }, [getFigureSkater, getFigureSkaterBody, getFigureSkaterGrip, getFigureSkaterHandLeft, getFigureSkaterHandRight, getFigureSkaterLegLeft, getFigureSkaterLegRight, getFigureSkaterShoulderLeft, getFigureSkaterShoulderRight, getFigureSkaterSkirt, getGround, getIceRink, getPlayer, getPlayerBody, getPlayerConstraint, getPlayerHand, getSnowflakes, getSpotlight, getTeddy, getTeddyConstraintLeft, getTeddyConstraintMiddle, getTeddyConstraintRight, getTeddyTexture, getWalls, iceRinkData.height, iceRinkData.y, screenHeight, screenWidth, standLength, startRespawnSnowflake, startUpdateTeddyTexture, stopChangeFigureSkaterPosition, stopCheckCollision, stopInclineFigureSkater, stopRespawnSnowflake, stopRotatePlayerHand, stopStoppingTeddy, stopUpdateCameraForFigureSkaterPosition, stopUpdateCameraForTeddyPosition, stopUpdateCameraFromTeddyToFigureSkaterPosition, stopUpdateTeddyTexture, toggleShownFigureSkater, toggleShownSpotlight, viewportWidth, watchRespawnSnowflake]);

  useEffect(() => {
    startRotatePlayerHand();
  }, [startRotatePlayerHand]);

  return (
    <>
      <Tribune ref={tribuneRef} width={[viewportWidth, viewportWidth * standLength - 1]} isScaled={false} />
      <canvas
        className={styles.GameScreen__canvas}
        ref={sceneRef}
        width={screenWidth}
        height={screenHeight}
        style={{ width: screenWidth, height: screenHeight }}
        onClick={detachTeddyFromHand}
      />
      <AboveScreenIndication
        ref={aboveScreenIndicationComponentRef}
        shown={shownTeddyBodyAboveScreenValue}
      />
    </>
  );
};

export default GameScreen;
