import { useCallback } from "react";
import { Bodies, Body, Composite, type World } from "matter-js";

import snowflakeTypeATexture from "./assets/textures/snowflake-type-a.png";
import snowflakeTypeBTexture from "./assets/textures/snowflake-type-b.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSnowflake() {
  const { screenHeight } = useScreen();

  const scale = 1 / 4;

  const getSnowflake = useCallback((areaWidth: number) => {
    const x = Math.random() * areaWidth;
    const y = Math.random() * screenHeight / 2;
    const size = Math.random() * (15 - 8) + 8;

    const type = Math.random() < 0.8 ? {
      scale: size / 25 * scale,
      texture: snowflakeTypeATexture,
    } : {
      scale: size / 33 * scale,
      texture: snowflakeTypeBTexture,
    };

    return Bodies.circle(x, y, size, {
      frictionAir: 0.2 + Math.random() * 0.3,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: type.texture,
          xScale: type.scale / 0.5,
          yScale: type.scale / 0.5,
        },
      },
    });
  }, [scale, screenHeight]);

  const getSnowflakes = useCallback((areaWidth: number, numSnowflakes: number) => {
    const bodies: Body[] = Array.from(Array(numSnowflakes).keys()).map(() => getSnowflake(areaWidth));

    return Composite.create({
      bodies,
    });
  }, [getSnowflake]);

  const respawnSnowflake = useCallback((world: World, areaWidth: number) => {
    Composite.allBodies(world)
      .forEach((body) => {
        if (body.position.y > screenHeight) {
          const x = Math.random() * areaWidth;

          Body.setPosition(body, {
            x,
            y: -10,
          });

          Body.setVelocity(body, { x: 0, y: 0 });
        }
      });
  }, [screenHeight]);

  return {
    getSnowflakes,
    respawnSnowflake,
  };
}
