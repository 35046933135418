import { forwardRef } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

export interface SpinnerProps {
  className?: string;
}

const Spinner = forwardRef<HTMLSpanElement, SpinnerProps>(({ className }, ref) => {
  return (
    <span ref={ref} className={clsx(styles.Spinner, className)}>
      <svg viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" />
      </svg>
    </span>
  );
});

Spinner.displayName = "Spinner";

export default Spinner;
