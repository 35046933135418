import { useCallback, useMemo } from "react";
import { Bodies, Composite } from "matter-js";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useWall() {
  const { screenHeight, viewportWidth, standLength } = useScreen();

  const wallWidth = viewportWidth;
  const wallHeight = screenHeight * 100;

  const leftWallData = useMemo(() => ({
    x: -200,
    y: screenHeight - wallHeight / 2,
    width: wallWidth,
    height: wallHeight,
  }), [screenHeight, wallHeight, wallWidth]);

  const rightWallData = useMemo(() => ({
    x: wallWidth * (standLength + 1),
    y: screenHeight - wallHeight / 2,
    width: wallWidth,
    height: wallHeight,
  }), [screenHeight, standLength, wallHeight, wallWidth]);

  const getWalls = useCallback(() => {
    return Composite.create({
      bodies: [
        Bodies.rectangle(leftWallData.x, leftWallData.y, leftWallData.width, leftWallData.height, {
          isStatic: true,
          render: {
            visible: false,
          },
          collisionFilter: {
            category: 0x1000,
          },
        }),
        Bodies.rectangle(rightWallData.x, rightWallData.y, rightWallData.width, rightWallData.height, {
          isStatic: true,
          render: {
            visible: false,
          },
          collisionFilter: {
            category: 0x1000,
          },
        }),
      ],
    });
  }, [leftWallData.height, leftWallData.width, leftWallData.x, leftWallData.y, rightWallData.height, rightWallData.width, rightWallData.x, rightWallData.y]);

  return {
    getWalls,
  };
}
