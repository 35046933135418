import { type ReactElement, useCallback, useEffect, useRef, useState } from "react";

import tribuneTexture from "./components/Tribune/assets/textures/tribune.png";
import tribuneStartTexture from "./components/Tribune/assets/textures/tribune-start.png";
import tribuneScaledTexture from "./components/Tribune/assets/textures/tribune-scaled.png";
import spotlightShadowTexture from "./objects/Spotlight/assets/textures/shadow.png";
import spotlightLightTexture from "./objects/Spotlight/assets/textures/light.png";
import staticFigureSkaterBodyTexture from "./objects/StaticFigureSkater/assets/textures/body.png";
import playerAsViewerBodyTexture from "./objects/PlayerAsViewer/assets/textures/body.png";
import playerHandTexture from "./objects/Player/assets/textures/hand.png";
import playerBodyTexture from "./objects/Player/assets/textures/body.png";
import snowflakeTypeATexture from "./objects/Snowflake/assets/textures/snowflake-type-a.png";
import snowflakeTypeBTexture from "./objects/Snowflake/assets/textures/snowflake-type-b.png";
import teddyBodyTexture from "./objects/Teddy/assets/textures/body.png";
import figureSkaterBodyTexture from "./objects/FigureSkater/assets/textures/body.png";
import figureSkaterSkirtTexture from "./objects/FigureSkater/assets/textures/skirt.png";
import figureSkaterLegLeftTexture from "./objects/FigureSkater/assets/textures/leg-left.png";
import figureSkaterLegRightTexture from "./objects/FigureSkater/assets/textures/leg-right.png";
import figureSkaterHandLeftTexture from "./objects/FigureSkater/assets/textures/hand-left.png";
import figureSkaterHandRightTexture from "./objects/FigureSkater/assets/textures/hand-right.png";
import figureSkaterShoulderLeftTexture from "./objects/FigureSkater/assets/textures/shoulder-left.png";
import figureSkaterShoulderRightTexture from "./objects/FigureSkater/assets/textures/shoulder-right.png";

import { type PreloadImageData, useImagePreloader } from "../../hooks/media/images";
import { usePopup } from "../../../components/Popup/hook/usePopup";
import { type ApiRequestStatue, type KnownApiError } from "../../../api/types";
import GameScore from "./components/Score";
import { type GameScreenType } from "./types";
import FinishResultPopup from "../../../popups/FinishResultPopup";
import ResultPopup from "../../../popups/ResultPopup";
import StartGameInstructionPopup from "../../../popups/StartGameInstructionPopup";
import Preload from "../../../components/Preload";
import TransitionLayout from "../../../components/TransitionLayout";
import GameLayout from "../../../components/GameLayout";
import GameScreen from "./screens/GameScreen";
import Attempts from "./components/Attempts";
import { api } from "../../../api";
import StartScreen from "./screens/StartScreen";

const preloadSrcList: PreloadImageData[] = [
  tribuneScaledTexture,
  tribuneStartTexture,
  tribuneTexture,
  spotlightShadowTexture,
  spotlightLightTexture,
  staticFigureSkaterBodyTexture,
  playerAsViewerBodyTexture,
  playerHandTexture,
  playerBodyTexture,
  snowflakeTypeATexture,
  snowflakeTypeBTexture,
  teddyBodyTexture,
  figureSkaterBodyTexture,
  figureSkaterSkirtTexture,
  figureSkaterLegLeftTexture,
  figureSkaterLegRightTexture,
  figureSkaterHandLeftTexture,
  figureSkaterHandRightTexture,
  figureSkaterShoulderLeftTexture,
  figureSkaterShoulderRightTexture,
];

export interface GameData {
  isActive: boolean;
  currentAttempts: number;
  bestScore: number;
  attemptsExists: boolean;
}

export interface GameDataResponse {
  success: boolean;
  data: GameData;
}

export interface PostGameData {
  value: number;
}

export default function Game(): ReactElement {
  const { isLoaded: imagesIsLoaded, progress } = useImagePreloader(preloadSrcList);

  const { showPopup, hidePopup, showInfoPopup } = usePopup();

  const gameScreenRef = useRef(null);
  const gameScoreComponentRef = useRef<HTMLDivElement>(null);
  const scoreValue = useRef<number>(0);

  const attemptsTotal = 3;

  const [shownResult, setShownResult] = useState<boolean>(false);

  const [getInfoStatus, setGetInfoStatus] = useState<ApiRequestStatue>("idle");
  const [sendInfoStatus, setSendInfoStatus] = useState<ApiRequestStatue>("idle");

  const [gameData, setGameData] = useState<GameData | null>(null);

  const { isActive, attemptsExists, currentAttempts, bestScore } = gameData ?? {};

  const isReady = getInfoStatus === "success" && imagesIsLoaded;

  const [devMode] = useState<boolean>(false);
  const [shownScore, setShownScore] = useState<boolean>(false);
  const [screen, setScreen] = useState<GameScreenType>("start");
  const [key, setKey] = useState<number>(0);

  const setScoreValue = useCallback((value: number) => {
    scoreValue.current = value;

    if (value > 0) {
      setShownScore(true);
    }

    if (gameScoreComponentRef.current) {
      gameScoreComponentRef.current.innerHTML = `${value.toFixed(2)}`;
    }
  }, []);

  const startGameAttempts = useCallback(async () => {
    if (devMode) {
      return;
    }

    try {
      await api.post<GameDataResponse>("game");
    } catch (error) {
      const err = error as KnownApiError;

      setSendInfoStatus("error");
      showInfoPopup({ message: err.response?.data.error });
    }
  }, [devMode, showInfoPopup]);

  const postGameData = useCallback(async (requestData: PostGameData) => {
    if (devMode) {
      setSendInfoStatus("success");
      setGameData({
        attemptsExists: true,
        bestScore: 0,
        isActive: true,
        currentAttempts: 0,
      });

      return;
    }

    try {
      setSendInfoStatus("process");

      const response = await api.put<GameDataResponse>("game", requestData);
      const { success, data } = response?.data ?? {};

      if (success) {
        setSendInfoStatus("success");
        setGameData(data);
      } else {
        setSendInfoStatus("error");
        showInfoPopup();
      }
    } catch (error) {
      const err = error as KnownApiError;

      setSendInfoStatus("error");
      showInfoPopup({ message: err.response?.data.error });
    }
  }, [devMode, showInfoPopup]);

  const showFinishResultPopup = useCallback((newBestScore: number) => {
    showPopup({
      id: "FinishResultPopup",
      content: (
        <FinishResultPopup
          result={{
            best: newBestScore,
          }}
        />
      ),
      exitButton: true,
    });
  }, [showPopup]);

  const showResultPopup = useCallback((endScoreValue: number) => {
    showPopup({
      id: "ResultPopup",
      content: (
        <ResultPopup
          result={{
            current: endScoreValue,
          }}
          onClick={() => {
            hidePopup();

            setShownResult(false);
            setSendInfoStatus("idle");


            setScoreValue(0);

            setKey((prev) => prev + 1);
          }}
        />
      ),
    });
  }, [hidePopup, setScoreValue, showPopup]);

  const endAttempt = useCallback(() => {
    setShownResult(true);
  }, []);

  useEffect(() => {
    const fetchGameData = async (): Promise<void> => {
      if (devMode) {
        setGetInfoStatus("success");
        setGameData({
          attemptsExists: true,
          isActive: true,
          currentAttempts: 0,
          bestScore: 0,
        });

        return;
      }

      try {
        setGetInfoStatus("process");

        const response = await api.get<GameDataResponse>("game");
        const { success, data } = response?.data ?? {};

        if (success) {
          setGetInfoStatus("success");
          setGameData(data);

          if (data.isActive && !data.attemptsExists) {
            showFinishResultPopup(data.bestScore);
          }
        } else {
          setGetInfoStatus("error");
          showInfoPopup();
        }
      } catch (error) {
        const err = error as KnownApiError;

        setGetInfoStatus("error");
        showInfoPopup({ message: err.response?.data.error });
      }
    };

    void fetchGameData()
      .then();
  }, [devMode, showFinishResultPopup, showInfoPopup]);

  useEffect(() => {
    if (isActive && !attemptsExists && shownResult && sendInfoStatus === "success") {
      showFinishResultPopup(bestScore ?? 0);
    }
  }, [attemptsExists, bestScore, isActive, sendInfoStatus, showFinishResultPopup, shownResult]);

  useEffect(() => {
    if (isActive && attemptsExists && shownResult && sendInfoStatus === "success") {
      showResultPopup(scoreValue.current);
    }
  }, [attemptsExists, isActive, sendInfoStatus, showResultPopup, shownResult]);

  useEffect(() => {
    if (isActive && attemptsExists && key === 0 && screen === "game") {
      showPopup({
        id: "StartGameInstructionPopup",
        content: <StartGameInstructionPopup />,
        variant: 'big_popup',
        customPosition: { y: 340 },
        background: false,
        notTouch: true,
      });
    }
  }, [attemptsExists, isActive, key, screen, showPopup]);

  return (
    <>
      <Preload shown={!isReady} progress={progress} />
      {
        isReady && (
          <>
            <TransitionLayout id="game-screen" shown={screen === "game"} duration={500} ref={gameScreenRef}>
              <GameLayout ref={gameScreenRef}>
                <GameScreen
                  key={key}
                  setShownScore={setShownScore}
                  setScoreValue={setScoreValue}
                  endAttempt={endAttempt}
                  startGameAttempts={startGameAttempts}
                  postGameData={postGameData}
                />
                <Attempts current={currentAttempts} total={attemptsTotal} />
                <GameScore ref={gameScoreComponentRef} shown={shownScore} />
              </GameLayout>
            </TransitionLayout>
            {
              screen === "start" && (
                <StartScreen
                  setScreen={() => {
                    hidePopup();
                    setScreen("game");
                  }}
                  isActive={isActive}
                  attemptsExists={attemptsExists}
                />
              )
            }
          </>
        )
      }
    </>
  );
};
