import { type Body, type Vector } from "matter-js";

export function reduceFlyoffSpeed(velocity: Vector, reductionFactor: number): Vector {
  return {
    x: velocity.x * reductionFactor,
    y: velocity.y * reductionFactor,
  };
}

export function lerp(start: number, end: number, t: number): number {
  return start * (1 - t) + end * t;
}

export function isBodyStopped(body: Body): boolean {
  const velocityThreshold = 0.02;
  const angularVelocityThreshold = 0.02;

  return (
    Math.abs(body.velocity.x) < velocityThreshold &&
    Math.abs(body.velocity.y) < velocityThreshold &&
    Math.abs(body.angularVelocity) < angularVelocityThreshold
  );
}
