import { type JSX } from "react";

export default function NotAccessGamePopup(): JSX.Element {
  return (
    <>
      <p>
        На&nbsp;данный момент игра недоступна.
        <br />
        Повтори свою попытку во&nbsp;время проведения мероприятия
      </p>
    </>
  );
}
