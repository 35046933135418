import { useCallback } from "react";
import { type Engine, Events } from "matter-js";

type StartRespawnSnowflakeFunction = (engine: Engine, watchRespawnSnowflake: () => void) => void;
type StopRespawnSnowflakeFunction = (engine: Engine, watchRespawnSnowflake: () => void) => void;

export default function useGameArea(): {
  startRespawnSnowflake: StartRespawnSnowflakeFunction;
  stopRespawnSnowflake: StopRespawnSnowflakeFunction;
} {
  const startRespawnSnowflake = useCallback<StartRespawnSnowflakeFunction>((engine, watchRespawnSnowflake) => {
    Events.on(engine, "afterUpdate", watchRespawnSnowflake);
  }, []);

  const stopRespawnSnowflake = useCallback<StopRespawnSnowflakeFunction>((engine, watchRespawnSnowflake) => {
    Events.off(engine, "afterUpdate", watchRespawnSnowflake);
  }, []);

  return {
    startRespawnSnowflake,
    stopRespawnSnowflake,
  };
}
