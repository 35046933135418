import { type ReactElement } from "react";
import clsx from "clsx";

import TeddyShadowIcon from "../../../../../icons/TeddyShadowIcon";
import TeddyIcon from "../../../../../icons/TeddyIcon";

import styles from "./styles.module.scss";

interface AttemptsProps {
  total: number;
  current: number | undefined;
}

export default function Attempts({ total, current = 0 }: AttemptsProps): ReactElement {
  return (
    <div className={styles.Attempts}>
      {
        Array.from(Array(total).keys()).map((i) => (
          <div key={`Attempts-item-$${i}`} className={styles.Attempts__item}>
            <TeddyShadowIcon />
            <div
              className={clsx(styles.Attempts__itemActive, current > i && styles.Attempts__itemActive_shown)}>
              <TeddyIcon />
            </div>
          </div>
        ))
      }
    </div>
  );
}
