import { forwardRef, useRef } from "react";

import TransitionLayout from "../../../../../components/TransitionLayout";

import styles from "./styles.module.scss";

interface GameScreenScoreProps {
  shown: boolean;
}

const GameScore = forwardRef<HTMLDivElement, GameScreenScoreProps>(({ shown }, ref) => {
  const scoreRef = useRef(null);

  return (
    <TransitionLayout id="game-score" shown={shown} ref={scoreRef} className={styles.GameScore}>
      <div ref={scoreRef} className={styles.GameScore__value}>
        <strong ref={ref}>0</strong>
      </div>
    </TransitionLayout>
  );
});

GameScore.displayName = "GameScore";

export default GameScore;
