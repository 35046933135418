import { forwardRef, type PropsWithChildren } from "react";

import styles from "./styles.module.scss";

const GameLayout = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
  return (
    <div ref={ref} className={styles.GameLayout}>
      {children}
    </div>
  );
});

GameLayout.displayName = "GameLayout";

export default GameLayout;
