import { type ReactElement } from "react";

import styles from "./styles.module.scss";

interface ProgressProps {
  progress: number;
}

export default function Progress({ progress }: ProgressProps): ReactElement {
  return (
    <div className={styles.Progress}>
      <div className={styles.Progress__value} style={{ width: `calc(${progress}% + 2px)` }} />
    </div>
  );
}
