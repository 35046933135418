import { type ReactElement } from "react";

import { getNoun } from "../../utils/text";

import styles from "./styles.module.scss";

interface FinishResultPopupProps {
  result: {
    best: number | undefined;
  };
}

export default function FinishResultPopup({ result: { best = 0 } }: FinishResultPopupProps): ReactElement {
  return (
    <div className={styles.FinishResultPopup}>
      <p className={styles.FinishResultPopup__title}>
        Твой лучший результат
      </p>
      <p className={styles.FinishResultPopup__result}>
        <b>
          {best === 0 ? 0 : best.toFixed(2)} {getNoun(best, ["балл", "балла", "баллов"])}
        </b>
      </p>
      <div className={styles.FinishResultPopup__hr} />
      <p>
        По&nbsp;окончании шоу тебе придет уведомление о&nbsp;результатах розыгрыша
      </p>
    </div>
  );
}
