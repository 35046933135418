import type { Bounds } from "matter-js";

import { lerp } from "./physics";

export function getCameraDeltaPosition({
                                         followBodyCenterX,
                                         currentBounds,
                                         offset,
                                         lerpFactor,
                                       }: {
  followBodyCenterX: number;
  currentBounds: Bounds;
  offset: number;
  lerpFactor: number;
}): { x: number; y: number; newCameraCenterX: number; cameraCenterX: number; } {
  const cameraCenterX = (currentBounds.min.x + currentBounds.max.x) / 2 - offset;

  const newCameraCenterX = lerp(cameraCenterX, followBodyCenterX, lerpFactor);

  const newX = newCameraCenterX - cameraCenterX;

  return {
    x: newX,
    y: 0,
    cameraCenterX,
    newCameraCenterX,
  };
}
