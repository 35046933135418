import { useCallback, useMemo } from "react";
import { Bodies } from "matter-js";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGround() {
  const { screenHeight, viewportWidth, standLength } = useScreen();

  const groundWidth = viewportWidth * 2 * (standLength + 1);
  const groundHeight = 70;

  const groundData = useMemo(() => ({
    x: 0,
    y: screenHeight - groundHeight / 2,
    width: groundWidth,
    height: groundHeight,
  }), [groundWidth, screenHeight]);

  const getGround = useCallback(() => {
    return Bodies.rectangle(groundData.x, groundData.y, groundData.width, groundData.height, {
      isStatic: true,
      render: {
        visible: false,
      },
      collisionFilter: {
        category: 0x1000,
      },
    });
  }, [groundData.height, groundData.width, groundData.x, groundData.y]);

  return {
    groundData,
    getGround,
  };
}
