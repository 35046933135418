import { type ReactElement } from "react";

import Button from "../../../../../../../components/Button";

import styles from "./styles.module.scss";

interface StartButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export default function StartButton({ disabled, onClick }: StartButtonProps): ReactElement {
  return (
    <Button
      className={styles.StartButton}
      onClick={onClick}
      disabled={disabled}
    >
      Начать игру
    </Button>
  );
}
