import { type JSX } from "react";

export default function MessageTriangleIcon(): JSX.Element {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6.91026C7.80614 6.13545 5.3128 3.47844 4.58619 0.0754395L0 4.96268C0 4.96268 4.92341 10.2103 11 6.91026Z"
        fill="#FFDD2D" />
    </svg>
  );
}
