import { type JSX } from "react";
import styles from "./styles.module.scss";

export default function Logo(): JSX.Element {
  return (
    <svg className={styles.Logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 235.5217645">
      <rect
        strokeWidth={0}
        fill="#ffdd2d"
        width="600"
        height="235.5217645"
        rx="26.4133264"
        ry="26.4133264" />
      <path
        strokeWidth={0}
        fill="#333"
        d="M293.6304324,113.5526635c-2.9700368-1.1424987-6.8544174-1.4850184-11.0813177-1.4850184h-23.5355878v-7.3118766h40.4437638v-12.5669108h-57.00942v54.3813282h38.615651c5.3699737,0,9.3675697-.4568845,12.3387559-1.5999579,6.2837428-2.3993622,10.5106431-8.2256457,10.5106431-15.8800421,0-7.7681865-3.9987454-13.1381602-10.2824882-15.5375224ZM285.4047867,132.4033171c-.9137691.3430944-2.1706326.4568845-3.7705905.4568845h-22.6206694v-8.6825302h22.6206694c1.8286875,0,3.3131312.1149395,4.4562046.6856142,1.3706536.6856142,2.0562678,1.9424776,2.0562678,3.5412862,0,1.5993832-.7994043,3.3131312-2.7418819,3.9987454Z" />
      <path
        strokeWidth={0}
        fill="#333"
        d="M350.3611241,92.1888576h-24.2200526l-22.2781497,54.3813282h18.7368635l3.0844016-7.883126h25.1338217l3.1993411,7.883126h18.6224987l-22.2787243-54.3813282ZM329.7967225,126.6908237l7.8837007-21.9350552h1.2562888l7.883126,21.9350552h-17.0231155Z" />
      <polygon
        strokeWidth={0}
        fill="#333"
        points="393.962497 126.3488349 393.962497 146.5700718 377.3973663 146.5700718 377.3973663 92.1886278 393.962497 92.1886278 393.962497 112.0675891 420.5821357 112.0675891 420.5821357 92.1886278 437.1481633 92.1886278 437.1481633 146.5700718 420.5821357 146.5700718 420.5821357 126.3488349 393.962497 126.3488349" />
      <polygon
        strokeWidth={0}
        fill="#333"
        points="463.3692401 126.0059341 463.3692401 146.5700718 446.8032124 146.5700718 446.8032124 92.1886278 463.3692401 92.1886278 463.3692401 112.6388525 467.9391572 112.6388525 487.9323848 92.1886278 508.7245251 92.1886278 482.2197778 118.2368264 509.7532205 146.5700718 487.7034989 146.5700718 467.9391572 126.0059341 463.3692401 126.0059341" />
      <path
        strokeWidth={0}
        fill="#fff"
        d="M79.3049121,57.2299076h132.0677372v66.420442c0,17.0272827-9.0835686,32.7616053-23.8287876,41.2761377l-42.2050492,24.370994-42.2049901-24.370994c-14.7455213-8.5145323-23.8289103-24.248855-23.8289103-41.2761377V57.2299076Z" />
      <path
        strokeWidth={0}
        fill="#333"
        fillRule="evenodd"
        d="M115.5501923,92.1886278v20.6581661c2.8249235-3.1900015,7.9611095-5.3489945,13.8336896-5.3489945h6.3818461v24.0148983c0,6.3890768-1.7359279,11.981362-4.311606,15.0573742h27.7596765c-2.5702905-3.0791312-4.3022487-8.6643276-4.3022487-15.0451813v-24.0270912h6.3821297c5.8725801,0,11.0086243,2.158993,13.8336896,5.3489945v-20.6581661h-59.5771767Z" />
    </svg>
  );
}
