import type Matter from "matter-js";
import { Bodies, type IBodyDefinition } from "matter-js";

interface CreateEllipseConfig {
  centerX: number;
  centerY: number;
  radiusX: number;
  radiusY: number;
  options?: IBodyDefinition;
  sides?: number;
}

export function createEllipse({
                                centerX,
                                centerY,
                                radiusX,
                                radiusY,
                                options,
                                sides = 20,
                              }: CreateEllipseConfig): Matter.Body {
  const vertices = [];

  for (let i = 0; i < sides; i++) {
    const angle = 2 * Math.PI * (i / sides);
    const x = centerX + radiusX * Math.cos(angle);
    const y = centerY + radiusY * Math.sin(angle);
    vertices.push({ x, y });
  }

  return Bodies.fromVertices(centerX, centerY, [vertices], options);
}

export function getAngle(angle: number): number {
  return (angle * Math.PI) / 180;
}
