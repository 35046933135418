import { useCallback, useMemo } from "react";
import { Bodies } from "matter-js";

import bodyTexture from "./assets/textures/body.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePlayerAsViewer() {
  const { screenHeight, viewportHeight } = useScreen();

  const playerAsViewerWidth = 171 / 3;
  const playerAsViewerHeight = 228 / 3;

  const playerAsViewerData = useMemo(() => ({
    x: playerAsViewerWidth / 2 + 40,
    y: screenHeight - playerAsViewerHeight / 2 - viewportHeight + 136,
    width: playerAsViewerWidth,
    height: playerAsViewerHeight,
  }), [playerAsViewerHeight, playerAsViewerWidth, screenHeight, viewportHeight]);

  const getPlayerAsViewer = useCallback(() => {
    return Bodies.rectangle(playerAsViewerData.x, playerAsViewerData.y, playerAsViewerData.width, playerAsViewerData.height, {
      isStatic: true,
      render: {
        sprite: {
          texture: bodyTexture,
          xScale: 1 / 3,
          yScale: 1 / 3,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [playerAsViewerData.height, playerAsViewerData.width, playerAsViewerData.x, playerAsViewerData.y]);

  return {
    getPlayerAsViewer,
  };
}
