import { useCallback, useMemo } from "react";
import { Bodies, Body, Constraint } from "matter-js";

import bodyTexture from "./assets/textures/body.png";
import handTexture from "./assets/textures/hand.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePlayer() {
  const { screenHeight } = useScreen();

  const scale = 1 / 3;

  const playerBodyWidth = 221 / 3;
  const playerBodyHeight = 750 / 3;

  const playerHandWidth = 119 / 3;
  const playerHandHeight = 321 / 3;
  const playerHandOffsetX = -9;
  const playerHandOffsetY = 33;

  const playerShoulderX = -1;
  const playerShoulderY = 19;

  const playerBodyData = useMemo(() => ({
    x: 92 + playerBodyWidth / 2,
    y: screenHeight - playerBodyHeight / 2 - 10,
    width: playerBodyWidth,
    height: playerBodyHeight,
  }), [playerBodyHeight, playerBodyWidth, screenHeight]);

  const playerHandData = useMemo(() => ({
    x: playerBodyData.x - playerBodyData.width / 2 + playerHandWidth / 2 + playerHandOffsetX,
    y: playerBodyData.y - playerBodyData.height / 2 + playerHandHeight / 2 + playerHandOffsetY,
    width: playerHandWidth,
    height: playerHandHeight,
  }), [playerBodyData.height, playerBodyData.width, playerBodyData.x, playerBodyData.y, playerHandHeight, playerHandOffsetX, playerHandWidth]);

  const getPlayerBody = useCallback(() => Bodies.rectangle(
    playerBodyData.x,
    playerBodyData.y,
    playerBodyData.width,
    playerBodyData.height,
    {
      render: {
        sprite: {
          texture: bodyTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        category: 0x0001,
        mask: 0x1000,
      },
    },
  ), [playerBodyData.height, playerBodyData.width, playerBodyData.x, playerBodyData.y, scale]);

  const getPlayerHand = useCallback(() => Bodies.rectangle(
    playerHandData.x,
    playerHandData.y,
    playerHandData.width,
    playerHandData.height,
    {
      render: {
        sprite: {
          texture: handTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        category: 0x0001,
        mask: 0x1000,
      },
      inertia: Infinity,
      frictionAir: 0,
    },
  ), [playerHandData.height, playerHandData.width, playerHandData.x, playerHandData.y, scale]);

  const getPlayerConstraint = useCallback((playerHand: Body) => Constraint.create({
    bodyB: playerHand,
    pointB: { x: -playerShoulderX, y: playerHandData.height / -2 + playerShoulderY },
    pointA: {
      x: playerHandData.x - playerShoulderX,
      y: playerHandData.y - playerHandData.height / 2 + playerShoulderY,
    },
    length: 0,
    stiffness: 0.9,
    damping: 0.1,
    render: {
      visible: false,
    },
  }), [playerHandData.height, playerHandData.x, playerHandData.y, playerShoulderX]);

  const getPlayer = useCallback((playerBody: Body) => Body.create({
    parts: [
      playerBody,
    ],
    collisionFilter: {
      mask: 0x0000,
    },
    isStatic: true,
  }), []);

  return {
    playerBodyData,
    playerHandData,
    getPlayerBody,
    getPlayerHand,
    getPlayerConstraint,
    getPlayer,
  };
}
