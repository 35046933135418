import { useCallback, useMemo } from "react";
import { Bodies } from "matter-js";

import bodyTexture from "./assets/textures/body.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useStaticFigureSkater() {
  const { screenWidth, screenHeight } = useScreen();

  const scale = 1 / 3;

  const figureSkaterBodyWidth = 696 / 3;
  const figureSkaterBodyHeight = 917 / 3;

  const figureSkaterBodyData = useMemo(() => ({
    x: screenWidth / 2 - 29 + figureSkaterBodyWidth / 2,
    y: screenHeight - figureSkaterBodyHeight / 2 - 24,
    width: figureSkaterBodyWidth,
    height: figureSkaterBodyHeight,
  }), [figureSkaterBodyHeight, figureSkaterBodyWidth, screenHeight, screenWidth]);

  const getStaticFigureSkater = useCallback(() => Bodies.rectangle(
    figureSkaterBodyData.x,
    figureSkaterBodyData.y,
    figureSkaterBodyData.width,
    figureSkaterBodyData.height,
    {
      isStatic: true,
      render: {
        sprite: {
          texture: bodyTexture,
          xScale: scale,
          yScale: scale,
        },
      },
    },
  ), [figureSkaterBodyData.height, figureSkaterBodyData.width, figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  return {
    getStaticFigureSkater,
  };
}
