import { forwardRef } from "react";

import tribuneStartTexture from "./assets/textures/tribune-start.png";
import tribuneMiddleTexture from "./assets/textures/tribune.png";
import tribuneScaledTexture from "./assets/textures/tribune-scaled.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

import styles from "./styles.module.scss";

interface TribuneProps {
  isScaled: boolean;
  width: [number, number];
}

const Tribune = forwardRef<HTMLDivElement, TribuneProps>(({ isScaled, width }, ref) => {
  const { viewportHeight } = useScreen();

  return (
    <div ref={ref} className={styles.Tribune}>
      {
        width[0] > 0 && (
          <div
            className={styles.Tribune__start}
            style={{
              backgroundImage: `url(${isScaled ? tribuneScaledTexture : tribuneStartTexture})`,
              width: `${width[0]}px`,
              height: `${viewportHeight}px`,
            }}
          />
        )
      }
      <div
        className={styles.Tribune__midlle}
        style={{
          backgroundImage: `url(${isScaled ? tribuneScaledTexture : tribuneMiddleTexture})`,
          width: `${width[1]}px`,
          height: `${viewportHeight}px`,
        }}
      />
    </div>
  );
});

Tribune.displayName = "Tribune";

export default Tribune;
