import { useCallback } from "react";
import { Bodies, Body } from "matter-js";

import shadowTexture from "./assets/textures/shadow.png";
import lightTexture from "./assets/textures/light.png";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSpotlight() {
  const getSpotlight = useCallback(({ x, y, width, height }: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => {
    const shadowRatio = 28 / 92;
    const shadowHeight = width * shadowRatio;
    const shadowScale = width / 92 / 3;

    const lightWidth = width * 110 / 92;
    const lightHeight = height;
    const lightScale = lightWidth / 110 / 3;

    const shadow = Bodies.rectangle(x, y - shadowHeight / 2, width, shadowHeight, {
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: shadowTexture,
          xScale: shadowScale,
          yScale: shadowScale,
        },
      },
    });

    const light = Bodies.rectangle(x - lightWidth * 0.09, y - lightHeight / 2 - shadowHeight / 2, lightWidth, lightHeight, {
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: lightTexture,
          yScale: lightHeight / 349.333 / 3,
          xScale: lightScale,
        },
      },
    });

    return Body.create({
      parts: [light, shadow],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });

  }, []);

  return {
    getSpotlight,
  };
}
