export function calculateScore({ position, offset, isActive }: {
  position: number;
  offset: number;
  isActive: boolean;
}): number {
  if (!isActive) {
    return 0;
  }

  const scaleFactor = 50;
  const rawScore = Math.max((position - offset) / scaleFactor, 0);
  const roundedScore = rawScore.toFixed(2);

  return +roundedScore;
}
