import { useCallback, useMemo } from "react";
import { Bodies } from "matter-js";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useIceRink() {
  const { screenHeight, viewportWidth, standLength } = useScreen();

  const iceRinkWidth = viewportWidth * standLength;
  const iceRinkHeight = 1;

  const iceRinkData = useMemo(() => ({
    x: viewportWidth + iceRinkWidth / 2,
    y: screenHeight - iceRinkHeight / 2 - 197,
    width: iceRinkWidth,
    height: iceRinkHeight,
  }), [iceRinkWidth, screenHeight, viewportWidth]);

  const getIceRink = useCallback(() => {
    return Bodies.rectangle(iceRinkData.x, iceRinkData.y, iceRinkData.width, iceRinkData.height, {
      isStatic: true,
      render: {
        visible: false,
      },
      friction: 0.001,
      collisionFilter: {
        category: 0x1000,
      },
    });
  }, [iceRinkData.height, iceRinkData.width, iceRinkData.x, iceRinkData.y]);

  return {
    iceRinkData,
    getIceRink,
  };
}
