import { useCallback, useMemo } from "react";
import { Bodies, Body, Composite } from "matter-js";

import bodyTexture from "./assets/textures/body.png";
import skirtTexture from "./assets/textures/skirt.png";
import legRightTexture from "./assets/textures/leg-right.png";
import legLeftTexture from "./assets/textures/leg-left.png";
import handLeftTexture from "./assets/textures/hand-left.png";
import handRightTexture from "./assets/textures/hand-right.png";
import shoulderLeftTexture from "./assets/textures/shoulder-left.png";
import shoulderRightTexture from "./assets/textures/shoulder-right.png";

import { useScreen } from "../../../../hooks/screen/useScreen";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFigureSkater() {
  const { screenHeight } = useScreen();

  const bodyWidth = 53;
  const bodyHeight = 78;

  const skirtWidth = 42;
  const skirtHeight = 38;

  const legLeftWidth = 42;
  const legLeftHeight = 38;

  const legRightWidth = 35;
  const legRightHeight = 95;

  const handRightWidth = 54;
  const handRightHeight = 60;

  const handLeftWidth = 48;
  const handLeftHeight = 42;

  const shoulderLeftWidth = 9;
  const shoulderLeftHeight = 18;

  const shoulderRightWidth = 10;
  const shoulderRightHeight = 17;

  const scale = 1 / 3;

  const figureSkaterBodyData = useMemo(() => ({
    x: 0,
    y: screenHeight - bodyHeight - 186,
    width: 128,
  }), [screenHeight]);

  const getFigureSkaterBody = useCallback(() => {
    const x = figureSkaterBodyData.x - 12;
    const y = figureSkaterBodyData.y - 42;

    const body = Bodies.rectangle(x, y, bodyWidth, bodyHeight, {
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: bodyTexture,
          xScale: scale,
          yScale: scale,
        },
      },
    });

    const point = Bodies.rectangle(x + 2, y + bodyHeight / 2 - 7, 5, 5, {
      density: 100000,
      render: {
        visible: false,
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      parts: [body, point],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterSkirt = useCallback(() => {
    return Bodies.rectangle(figureSkaterBodyData.x, figureSkaterBodyData.y, skirtWidth, skirtHeight, {
      label: "figureSkaterSkirt",
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: skirtTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      // inertia: Infinity,
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterLegRight = useCallback(() => {
    const x = figureSkaterBodyData.x + 17;
    const y = figureSkaterBodyData.y + 41.5;

    const body = Bodies.rectangle(x, y, legRightWidth, legRightHeight, {
      render: {
        sprite: {
          texture: legRightTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    const point = Bodies.rectangle(x - legRightWidth / 2 + 5, y - legRightHeight / 2 + 18, 5, 5, {
      density: 100000,
      render: {
        visible: false,
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      label: "figureSkaterLegRight",
      parts: [body, point],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterLegLeft = useCallback(() => {
    const x = figureSkaterBodyData.x - 5.5;
    const y = figureSkaterBodyData.y + 43.5;

    const body = Bodies.rectangle(x, y, legLeftWidth, legLeftHeight, {
      render: {
        sprite: {
          texture: legLeftTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    const point = Bodies.rectangle(x - legLeftWidth / 2 + 27, y - legLeftHeight / 2 - 20, 5, 5, {
      density: 100000,
      render: {
        visible: false,
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      label: "figureSkaterLegLeft",
      parts: [body, point],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterHandLeft = useCallback(() => {
    const x = figureSkaterBodyData.x - 45.3;
    const y = figureSkaterBodyData.y - 21.8;

    const body = Bodies.rectangle(x, y, handLeftWidth, handLeftHeight, {
      render: {
        sprite: {
          texture: handLeftTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    const point = Bodies.rectangle(x + handLeftWidth / 2 - 0.5, y - handLeftHeight / 2 + 6.5, 5, 5, {
      density: 100000,
      render: {
        visible: false,
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      label: "figureSkaterHandLeft",
      parts: [body, point],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterHandRight = useCallback(() => {
    const x = figureSkaterBodyData.x + 20;
    const y = figureSkaterBodyData.y - 12;

    const body = Bodies.rectangle(x, y, handRightWidth, handRightHeight, {
      render: {
        sprite: {
          texture: handRightTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    const point = Bodies.rectangle(x - handRightWidth / 2 + 10, y - handRightHeight / 2 + 5.5, 5, 5, {
      density: 100000,
      render: {
        visible: false,
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      label: "figureSkaterHandRight",
      parts: [body, point],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterShoulderLeft = useCallback(() => {
    const x = figureSkaterBodyData.x - 24;
    const y = figureSkaterBodyData.y - 35;

    const body = Bodies.rectangle(x, y, shoulderLeftWidth, shoulderLeftHeight, {
      render: {
        sprite: {
          texture: shoulderLeftTexture,
          xScale: scale,
          yScale: scale
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      parts: [body],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterShoulderRight = useCallback(() => {
    const x = figureSkaterBodyData.x + 2;
    const y = figureSkaterBodyData.y - 35.4;

    const body = Bodies.rectangle(x, y, shoulderRightWidth, shoulderRightHeight, {
      render: {
        sprite: {
          texture: shoulderRightTexture,
          xScale: scale,
          yScale: scale,
        },
      },
      collisionFilter: {
        mask: 0x0000,
      },
    });

    return Body.create({
      parts: [body],
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y, scale]);

  const getFigureSkaterGrip = useCallback(() => {
    const x = figureSkaterBodyData.x + -60;
    const y = figureSkaterBodyData.y - 9;

    return Bodies.rectangle(x, y, 5, 5, {
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        opacity: 0,
        visible: false,
      },
    });
  }, [figureSkaterBodyData.x, figureSkaterBodyData.y]);

  const getFigureSkater = useCallback((body: Body, skirt: Body, legLeft: Body, legRight: Body, handLeft: Body, handRight: Body, shoulderLeft: Body, shoulderRight: Body, grip: Body) => {
    const bodyWithHands = Composite.create({
      label: "figureSkaterBodyWithHands",
      bodies: [handLeft, shoulderLeft, shoulderRight, body, skirt, handRight, grip],
    });

    return Composite.create({
      composites: [bodyWithHands],
      bodies: [legRight, legLeft],
    });
  }, []);

  const getFigureSkaterCopy = useCallback((body: Body, skirt: Body, legLeft: Body, legRight: Body, handLeft: Body, handRight: Body, shoulderLeft: Body, shoulderRight: Body, grip: Body) => {
    const bodyWithHands = Composite.create({
      label: "figureSkaterBodyWithHands",
      bodies: [handLeft, shoulderLeft, shoulderRight, handRight, body, grip],
    });

    const legs = Composite.create({
      label: "figureSkaterLegsWithSkirt",
      bodies: [legRight, legLeft, skirt],
    });

    return Composite.create({
      composites: [bodyWithHands, legs],
    });
  }, []);

  return {
    figureSkaterBodyData,
    getFigureSkaterBody,
    getFigureSkaterSkirt,
    getFigureSkaterLegLeft,
    getFigureSkaterLegRight,
    getFigureSkaterHandLeft,
    getFigureSkaterHandRight,
    getFigureSkaterShoulderLeft,
    getFigureSkaterShoulderRight,
    getFigureSkaterGrip,
    getFigureSkater,
    getFigureSkaterCopy,
  };
}
