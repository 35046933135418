import { type JSX } from "react";

import CursorPointerIcon from "../../icons/CursorPointerIcon";

import styles from "./styles.module.scss";

export default function StartGameInstructionPopup(): JSX.Element {
  return (
    <>
      <p>
        Чтобы бросить мишку, нажми однократно на&nbsp;экран. У&nbsp;тебя есть три попытки, засчитывается лучший&nbsp;результат.
        <br />
        Чтобы бросить дальше, подожди, пока я&nbsp;раскручу руку и&nbsp;наберу силу броска
      </p>
      <CursorPointerIcon className={styles.StartGameInstructionPopup__icon} />
    </>
  );
}
