import { type JSX } from "react";

import { ScreenProvider } from "./game/hooks/screen/context";
import { PopupProvider } from "./components/Popup";
import Logo from "./components/Logo";
import Game from "./game/components/Game";

window?.Telegram?.WebApp?.expand();
window?.Telegram?.WebApp?.enableClosingConfirmation();

export default function App(): JSX.Element {
  return (
    <ScreenProvider>
      <PopupProvider>
        <Logo />
        <Game />
      </PopupProvider>
    </ScreenProvider>
  );
}
