import { type ReactElement, useRef } from "react";

import TransitionLayout from "../TransitionLayout";
import Progress from "../Progress";

import styles from "./styles.module.scss";

interface PreloadProps {
  shown: boolean;
  progress: number;
}

export default function Preload({ shown, progress }: PreloadProps): ReactElement {
  const preloadRef = useRef(null);

  return (
    <TransitionLayout
      id="game-score"
      shown={shown}
      ref={preloadRef}
      className={styles.Preload}
    >
      <div ref={preloadRef} className={styles.Preload__content}>
        <strong className={styles.Preload__label}>Загрузка</strong>
        <Progress progress={progress} />
      </div>
    </TransitionLayout>
  );
}
