import { type JSX } from "react";

import styles from "./styles.module.scss";

export default function GameInstructionPopup(): JSX.Element {
  return (
    <>
      <b className={styles.GameInstructionPopup__title}>
        Брось мне мишку!
      </b>
      <p>
        У&nbsp;тебя 3&nbsp;попытки. Лучшая попытка будет добавлена в&nbsp;рейтинг
      </p>
    </>
  );
}
