import { forwardRef, type PropsWithChildren } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface TransitionLayoutProps extends PropsWithChildren {
  shown: boolean;
  id: string;
  name?: "popup" | "fade";
  duration?: number;
  className?: string;
}

const TransitionLayout = forwardRef<HTMLDivElement, TransitionLayoutProps>(({
                                                                              shown,
                                                                              id,
                                                                              name = "fade",
                                                                              duration = 300,
                                                                              className,
                                                                              children,
                                                                            }, ref) => {
  return (
    <SwitchTransition>
      <CSSTransition
        nodeRef={ref}
        key={shown ? `${id}-shown-in` : `${id}-shown-out`}
        timeout={duration}
        classNames={{
          enter: styles[`TransitionLayout_${name}_${duration}_Enter`],
          enterActive: styles[`TransitionLayout_${name}_${duration}_Enter_active`],
          exit: styles[`TransitionLayout_${name}_${duration}_Exit`],
          exitActive: styles[`TransitionLayout_${name}_${duration}_Exit_active`],
        }}
      >
        <div className={clsx(styles.TransitionLayout, className)}>
          {
            shown && (
              children
            )
          }
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
});

TransitionLayout.displayName = "TransitionLayout";

export default TransitionLayout;
