import { forwardRef, useRef } from "react";

import ArrowIcon from "../../../../../../../icons/ArrowIcon";
import TransitionLayout from "../../../../../../../components/TransitionLayout";

import styles from "./styles.module.scss";

interface AboveScreenIndicationProps {
  shown: boolean;
}

const AboveScreenIndication = forwardRef<HTMLDivElement, AboveScreenIndicationProps>(({ shown }, ref) => {
  const indicationRef = useRef(null);

  return (
    <TransitionLayout id="above-screen-indication" shown={shown} ref={indicationRef} className={styles.GameScore}>
      <div ref={indicationRef} className={styles.AboveScreenIndication}>
        <ArrowIcon />
        <b ref={ref} className={styles.AboveScreenIndication__value}>0</b>
      </div>
    </TransitionLayout>

  );
});

AboveScreenIndication.displayName = "AboveScreenIndication";

export default AboveScreenIndication;
