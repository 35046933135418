import { useCallback, useMemo } from "react";
import { Bodies, Body, Constraint } from "matter-js";

import bodyTexture from "./assets/textures/body.png";

import { usePlayer } from "../Player";
import { createEllipse, getAngle } from "../../helpers/figure";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useTeddy() {
  const { playerHandData } = usePlayer();

  const scale = 1 / 3;

  const teddyWidth = 151 / 3;
  const teddyHeight = 138 / 3;

  const teddyBodyXRadius = 22;
  const teddyBodyYRadius = 10;

  const teddyHeadXRadius = 10;
  const teddyHeadYRadius = 8;

  const teddyLegXRadius = 8;
  const teddyLegYRadius = 7;

  const teddyOrgansConstraintLength = 0;
  const teddyOrgansConstraintStiffness = 0.9;
  const teddyOrgansConstraintDamping = 0.0025;

  const constraintPointAX = playerHandData.width / 2 - 5 - 7.1;
  const constraintPointAY = playerHandData.height / 2 - 2.6 + 5 - 10;
  const constraintPointBX = teddyHeadXRadius - 3 - 7;
  const constraintPointBY = -teddyHeadYRadius * 2 - 2 + 5 - 5;

  const teddyData = useMemo(() => ({
    label: "Teddy",
    x: playerHandData.x + playerHandData.width / 2 - 12,
    y: playerHandData.y + playerHandData.height / 2 + teddyHeight / 2 - 12,
    width: teddyWidth,
    height: teddyHeight,
  }), [playerHandData.height, playerHandData.width, playerHandData.x, playerHandData.y, teddyHeight, teddyWidth]);

  const getTeddyHead = useCallback(() => createEllipse({
    centerX: teddyData.x,
    centerY: teddyData.y - teddyHeight / 2 + teddyHeadYRadius,
    radiusX: teddyHeadXRadius,
    radiusY: teddyHeadYRadius,
    options: {
      label: teddyData.label,
      collisionFilter: {
        category: 0x0002,
      },
    },
  }), [teddyData.label, teddyData.x, teddyData.y, teddyHeight]);

  const getTeddyBody = useCallback(() => createEllipse({
    centerX: teddyData.x,
    centerY: teddyData.y + teddyHeight / 2 - teddyBodyYRadius - 5,
    radiusX: teddyBodyXRadius,
    radiusY: teddyBodyYRadius,
    options: {
      label: teddyData.label,
      collisionFilter: {
        category: 0x0002,
      },
    },
  }), [teddyData.label, teddyData.x, teddyData.y, teddyHeight]);

  const getTeddyLeftLeg = useCallback(() => createEllipse({
    centerX: teddyData.x - teddyWidth / 2 + teddyLegXRadius,
    centerY: teddyData.y + teddyHeight / 2 - teddyLegYRadius - 5,
    radiusX: teddyLegXRadius,
    radiusY: teddyLegYRadius,
    options: {
      label: teddyData.label,
      angle: getAngle(40),
      collisionFilter: {
        category: 0x0002,
      },
    },
  }), [teddyData.label, teddyData.x, teddyData.y, teddyHeight, teddyWidth]);

  const getTeddyRightLeg = useCallback(() => createEllipse({
    centerX: teddyData.x + teddyWidth / 2 - teddyLegXRadius,
    centerY: teddyData.y + teddyHeight / 2 - teddyLegYRadius - 5,
    radiusX: teddyLegXRadius,
    radiusY: teddyLegYRadius,
    options: {
      label: teddyData.label,
      angle: getAngle(-40),
      collisionFilter: {
        category: 0x0002,
      },
    },
  }), [teddyData.label, teddyData.x, teddyData.y, teddyHeight, teddyWidth]);

  const getTeddy = useCallback(() => {
    return Body.create({
      parts: [getTeddyLeftLeg(), getTeddyRightLeg(), getTeddyBody(), getTeddyHead()],
      label: teddyData.label,
      collisionFilter: {
        category: 0x0002,
      },
      friction: 0.00001,
      frictionAir: 0.004,
      render: {
        visible: false,
      },
    });
  }, [getTeddyBody, getTeddyHead, getTeddyLeftLeg, getTeddyRightLeg, teddyData.label]);

  const getTeddyTexture = useCallback(() => {
    return Bodies.rectangle(teddyData.x, teddyData.y, teddyData.width, teddyData.height, {
      isStatic: true,
      collisionFilter: {
        mask: 0x0000,
      },
      render: {
        sprite: {
          texture: bodyTexture,
          xScale: scale,
          yScale: scale,
        },
      },
    });
  }, [scale, teddyData.height, teddyData.width, teddyData.x, teddyData.y]);

  const getTeddyConstraintLeft = useCallback((teddy: Body, playerHand: Body) => Constraint.create({
    bodyB: teddy,
    pointB: { x: constraintPointBX - 5, y: constraintPointBY - 2 },
    bodyA: playerHand,
    pointA: { x: constraintPointAX - 5, y: constraintPointAY - 2 },
    length: teddyOrgansConstraintLength,
    stiffness: teddyOrgansConstraintStiffness,
    damping: teddyOrgansConstraintDamping,
    render: {
      visible: false,
    },
  }), [constraintPointAX, constraintPointAY, constraintPointBX, constraintPointBY]);

  const getTeddyConstraintRight = useCallback((teddy: Body, playerHand: Body) => Constraint.create({
    bodyB: teddy,
    pointB: { x: constraintPointBX + 5, y: constraintPointBY - 2 },
    bodyA: playerHand,
    pointA: { x: constraintPointAX + 5, y: constraintPointAY - 2 },
    length: teddyOrgansConstraintLength,
    stiffness: teddyOrgansConstraintStiffness,
    damping: teddyOrgansConstraintDamping,
    render: {
      visible: false,
    },
  }), [constraintPointAX, constraintPointAY, constraintPointBX, constraintPointBY]);

  const getTeddyConstraintMiddle = useCallback((teddy: Body, playerHand: Body) => Constraint.create({
    bodyB: teddy,
    pointB: { x: constraintPointBX, y: constraintPointBY },
    bodyA: playerHand,
    pointA: { x: constraintPointAX, y: constraintPointAY },
    length: teddyOrgansConstraintLength,
    stiffness: teddyOrgansConstraintStiffness,
    damping: teddyOrgansConstraintDamping,
    render: {
      visible: false,
    },
  }), [constraintPointAX, constraintPointAY, constraintPointBX, constraintPointBY]);

  return {
    teddyData,
    getTeddy,
    getTeddyTexture,
    getTeddyConstraintLeft,
    getTeddyConstraintMiddle,
    getTeddyConstraintRight,
  };
}
