import { type ReactElement } from "react";

import Button, { type ButtonProps } from "../../components/Button";
import { getNoun } from "../../utils/text";

import styles from "./styles.module.scss";

interface ResultPopupProps {
  result: {
    current: number;
  };
  inProcess?: boolean;
  onClick: ButtonProps["onClick"];
}

export default function ResultPopup({
                                      result: { current },
                                      inProcess,
                                      onClick,
                                    }: ResultPopupProps): ReactElement {
  return (
    <div className={styles.ResultPopup}>
      <p className={styles.ResultPopup__title}>
        Твой результат
      </p>
      <p className={styles.ResultPopup__result}>
        <b>
          {current === 0 ? 0 : current.toFixed(2)} {getNoun(current, ["балл", "балла", "баллов"])}
        </b>
      </p>
      <Button
        className={styles.ResultPopup__button}
        inProcess={inProcess}
        onClick={onClick}
      >
        Бросить еще раз
      </Button>
      <p>
        Мы&nbsp;засчитаем лучший результат
      </p>
    </div>
  );
}
