import clsx from "clsx";
import { type PropsWithChildren, type ReactElement, useRef } from "react";

import TransitionLayout from "../../../TransitionLayout";
import Button from "../../../Button";
import MessageTriangleIcon from "../../../../icons/MessageTriangleIcon";

import styles from "./styles.module.scss";

export type PopupContentColor = "white" | "yellow";

export type PopupContentVariant = "popup" | "message" | 'big_popup';

interface PopupProps extends PropsWithChildren {
  id: string | undefined;
  shown: boolean;
  customPosition?: { x?: number; y?: number; };
  background?: boolean;
  exitButton?: boolean;
  color?: PopupContentColor;
  variant?: PopupContentVariant;
  notTouch?: boolean;
}

export default function PopupContent({
                                       id,
                                       shown,
                                       customPosition,
                                       background = true,
                                       color = "white",
                                       variant = "popup",
                                       exitButton,
                                       notTouch,
                                       children,
                                     }: PopupProps): ReactElement {
  const popupRef = useRef(null);
  const popupBackgroundRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <TransitionLayout id={id ?? "popup"} shown={shown} name="popup" className={styles.PopupContent} ref={popupRef}>
        <div
          ref={popupRef}
          className={clsx(
            styles.PopupContent__content,
            notTouch && styles.PopupContent__content_not_touch,
            customPosition && styles.PopupContent__content_custom_position,
            variant && styles[`PopupContent__content_variant_${variant}`],
            color && styles[`PopupContent__content_color_${color}`],
          )}
          style={{
            bottom: customPosition?.y ? `${customPosition.y}px` : "auto",
            left: "50%",
            marginLeft: `${(variant === "popup" ? -109 : variant === 'big_popup' ? -125 : -85) - (customPosition?.x ?? 0)}px`,
          }}
        >
          {children}
          {
            exitButton && (
              <Button
                className={styles.PopupContent__close}
                onClick={() => {
                  window?.Telegram?.WebApp?.close();
                }}
              >
                Выйти из игры
              </Button>
            )
          }
          {
            variant === "message" && (
              <div className={styles.PopupContent__triangle}>
                <MessageTriangleIcon />
              </div>
            )
          }
        </div>
      </TransitionLayout>
      <TransitionLayout
        id={`${id}-background`}
        shown={shown && background}
        className={styles.PopupBackground}
        ref={popupBackgroundRef}
      >
        <div
          ref={popupBackgroundRef}
          className={clsx(styles.PopupBackground__content)}
        />
      </TransitionLayout>
    </>
  );
}
