import { useEffect, useState } from "react";

export type PreloadImageData = string;

interface ImagePreloadHook {
  isLoaded: boolean;
  progress: number;
}

const preloadImage = async (src: string): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve();
    };

    img.onerror = img.onabort = () => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject("Upload image error");
    };

    img.src = src;
  });
};

export const useImagePreloader = (imageSources: string[]): ImagePreloadHook => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!imageSources.length) {
      setIsLoaded(true);
      return;
    }

    let loadedCount = 0;

    const updateProgress = (loaded: number, total: number): void => {
      setProgress((loaded / total) * 100);
    };

    Promise.all(
      imageSources.map(async (src) => {
          await preloadImage(src)
            .then(() => {
              loadedCount++;
              updateProgress(loadedCount, imageSources.length);
            })
            .catch((error) => {
              console.error(`Error loading image ${src}: `, error);
            });
        },
      ),
    )
      .then(() => {
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error preloading images: ", error);
      });
  }, [imageSources]);

  return { isLoaded, progress };
};
