import { useContext } from "react";

import { ScreenContext, type ScreenData } from "./context";

export function useScreen(): ScreenData {
  const context = useContext(ScreenContext);

  if (!context) {
    throw new Error("useScreen must be used within a WindowSizeProvider");
  }

  return context;
}
